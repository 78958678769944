/**
 * Copyright 2019-2022 Wingify Software Pvt. Ltd.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

const VWOFeatureFlags = {
  init(settingsFile) {
    VWOFeatureFlags.isEventArchEnabled = settingsFile.isEventArchEnabled;
    VWOFeatureFlags.isNB = settingsFile.isNB;
    VWOFeatureFlags.isNBv2 = settingsFile.isNBv2;
  },

  getAll() {
    return {
      isEventArchEnabled: VWOFeatureFlags.isEventArchEnabled,
      isNB: VWOFeatureFlags.isNB,
      isNBv2: VWOFeatureFlags.isNBv2
    };
  }
};

module.exports = VWOFeatureFlags;
